import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "submitButton"];

  connect() {
    this.toggleSubmit();
  }

  toggleSubmit() {
    const checkedCheckboxes = this.checkboxTargets.filter(checkbox => checkbox.checked);
    this.submitButtonTarget.disabled = checkedCheckboxes.length === 0;
  }

  checkboxChanged(event) {
    this.toggleSubmit();
  }
}
