import { Controller } from "stimulus"

export default class extends Controller {
  openSeatGroupStartModal(event) {
    const seatGroupId = event.target.getAttribute('data-seat-group-id');
    const seatGroupStartModalId = "#seat-group-start-modal-" + seatGroupId;
    const seatGroupStatusModalId = "#seat-group-status-modal-" + seatGroupId;
    $(seatGroupStartModalId).modal('show');
    $(seatGroupStatusModalId).modal('hide');
  }

  openChangeSeatGroupModal(event) {
    const seatGroupId = event.target.getAttribute('data-seat-group-id');
    // 変更先のテーブルの初期値を表示先のモーダルに反映する処理
    this.changeSeatGroup(seatGroupId);

    const changeSeatGroupModalId = "#swap-seat-group-modal-" + seatGroupId;
    const seatGroupStatusModalId = "#seat-group-status-modal-" + seatGroupId;
    $(changeSeatGroupModalId).modal('show');
    $(seatGroupStatusModalId).modal('hide');
  }

  openChangeStatusModal(event) {
    const seatGroupId = event.target.getAttribute('data-seat-group-id');
    const changeStatusModalId = "#update-status-modal-" + seatGroupId;
    const seatGroupStatusModalId = "#seat-group-status-modal-" + seatGroupId;
    $(changeStatusModalId).modal('show');
    $(seatGroupStatusModalId).modal('hide');
  }

  changeStatus(event) {
    const seatGroupId = event.target.getAttribute('data-seat-group-id');
    const selectStatus = document.getElementById("select-status-" + seatGroupId);

    selectStatus.addEventListener("change", function() {
      const selectedValue = selectStatus.value;
      const hiddenFieldStatusId = "update-status-" + seatGroupId;
      document.getElementById(hiddenFieldStatusId).value = selectedValue;
    });
  }

  changeSeatGroup(seatGroupId) {
    const selectName = document.getElementById("select-seat-group-" + seatGroupId);
    const selectedValue = selectName.value;
    const selectedKey = selectName.options[selectName.selectedIndex].textContent;
    const hiddenFieldNameId = "hidden-swap-seat-group-" + seatGroupId;
    const displayedNameFieldNameId = "swap-seat-group-" + seatGroupId;
    document.getElementById(hiddenFieldNameId).value = selectedValue;
    document.getElementById(displayedNameFieldNameId).textContent = selectedKey;
  }

  openUpdateStatusIntervalModal(event) {
    event.preventDefault();

    $('#update-seat-group-status-interval-modal').modal('show');
  }

  openSeatGroupOrderModal(event) {
    event.preventDefault();

    const seatGroupId = event.target.getAttribute('data-seat-group-id');
    const seatGroupOrderModalId = "#seat-group-order-modal-" + seatGroupId;
    $(seatGroupOrderModalId).modal('show');
  }

  openQrReissueModal(event) {
    const seatGroupId = event.target.getAttribute('data-seat-group-id');
    const seatGroupQrReissueModalId = "#seat-group-qr-reissue-modal-" + seatGroupId;
    const seatGroupStatusModalId = "#seat-group-status-modal-" + seatGroupId;
    $(seatGroupQrReissueModalId).modal('show');
    $(seatGroupStatusModalId).modal('hide');
  }

  openQrDisplayModal(event) {
    const seatGroupId = event.target.getAttribute('data-seat-group-id');
    const seatGroupQrDisplayModalId = "#seat-group-qr-display-modal-" + seatGroupId;
    const seatGroupStatusModalId = "#seat-group-status-modal-" + seatGroupId;
    $(seatGroupQrDisplayModalId).modal('show');
    $(seatGroupStatusModalId).modal('hide');
  }
}
